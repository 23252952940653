import React, { Component, useState } from 'react';
import UnityWindow from 'components/UnityWindowWebXRDemo'
import { Flex, Heading, Button } from '@thenexlabs/uikit'
import { useTranslation } from 'contexts/Localization'

const WebXRPage: React.FC<> = () => {

  const { t } = useTranslation()


  return (
    <>
      <div style={{margin: '100px 50px'}}>
        <Heading scale="xxl" color="secondary" mb="24px">
          {t('WebXR: The Next Level of Immersive Experiences')}
        </Heading>
        <Heading scale="md" color='text'>
          WebXR is a technology that enables the creation of immersive experiences in the browser, such as virtual and augmented reality. It builds upon the foundations laid by previous web technologies and takes it to the next level by introducing advanced features and capabilities that were previously impossible.
        </Heading>
        <Heading scale="lg" color="secondary" mt="24px" mb="10px">
          What Makes WebXR Different?
        </Heading>
        <Heading scale="md" color='text'>
          While previous web technologies have laid the groundwork for the modern internet, WebXR takes it a step further by introducing advanced features such as virtual and augmented reality. This means that the internet will be able to provide immersive and interactive experiences that were previously impossible. Additionally, WebXR is designed to be accessible to everyone, regardless of the device they use or their level of technical expertise.
        </Heading>
        <Heading scale="lg" color="secondary" mt="24px" mb="10px">
          What Can You Do With WebXR?
        </Heading>
        <Heading scale="md" color='text'>
          With WebXR, you can create immersive and interactive experiences that can be accessed from any device with a browser. You can build virtual and augmented reality applications that can be used for gaming, education, training, or any other purpose you can imagine. Additionally, you can take advantage of advanced features such as spatial audio, haptic feedback, and motion tracking to create experiences that are more engaging and realistic.
        </Heading>
        <Heading scale="md" color='text'>
          WebXR represents the next level of immersive experiences, and it has the potential to revolutionize the way we interact with the digital world. So why not join us on this journey and help us build the future?
        </Heading>
      </div>
      <Heading style={{margin: 'auto', textAlign: 'center'}} scale="xl" color="secondary" mb="24px">
        {t('WebXR Demo Below')}
      </Heading>
      <UnityWindow/>
    </>
  );
};

export default WebXRPage;