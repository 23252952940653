import type {
  UnknownAsyncThunkFulfilledAction,
  UnknownAsyncThunkPendingAction,
  UnknownAsyncThunkRejectedAction,
  // eslint-disable-next-line import/no-unresolved
} from '@reduxjs/toolkit/dist/matchers'
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'
import stringify from 'fast-json-stable-stringify'
import type { AppState } from 'state'
import { fetchPublicNexItems } from './fetchNexItems'
import { fetchUserNexItems } from './fetchNexItemsUser'
import {
  SerializedNexItemsState,
  SerializedNexItems,
  SerializedNexItemsUserData
} from '../types'


const noDataNexItemsConfig = { // public
  keycard: {
    totalSupply: '0',
    saleState: '0',
    maxSupply: '0'
  },
  core: {
    totalSupply: '0',
    saleState: '0',
    maxSupply: '0'
  }
}

const noAccountNexItemsConfig = { // user
  keycard: {
    numMinted: '0',
    balance: '0'
  },
  core: {
    numMinted: '0',
    balance: '0'
  }
}

const initialState: SerializedNexItemsState = {
  userData: noAccountNexItemsConfig,
  data: noDataNexItemsConfig,
  loadArchivedNexItemsData: false,
  userDataLoaded: false,
  loadingKeys: {},
}

// Async thunks
export const fetchNexItemsPublicDataAsync = createAsyncThunk<
  SerializedNexItems,
  string,
  {
    state: AppState
  }
>(
  'nexItems/fetchNexItemsPublicDataAsync',
  async ( x ) => {

    const payload = await fetchPublicNexItems()

    return payload
  },
  {
    condition: (arg, { getState }) => {
      const { nexItems } = getState()
      if (nexItems.loadingKeys[stringify({ type: fetchNexItemsPublicDataAsync.typePrefix, arg })]) {
        console.debug('nexItems action is fetching, skipping here')
        return false
      }
      return true
    },
  },
)


export const fetchNexItemsUserDataAsync = createAsyncThunk<
  SerializedNexItemsUserData,
  string,
  {
    state: AppState
  }
>(
  'nexItems/fetchNexItemsUserDataAsync',
  async ( account ) => {

    const payload = await fetchUserNexItems(account)

    return payload
  },
  {
    condition: (arg, { getState }) => {
      const { nexItems } = getState()
      if (nexItems.loadingKeys[stringify({ type: fetchNexItemsUserDataAsync.typePrefix, arg })]) {
        console.debug('nexItems user action is fetching, skipping here')
        return false
      }
      return true
    },
  },
)

type UnknownAsyncThunkFulfilledOrPendingAction =
  | UnknownAsyncThunkFulfilledAction
  | UnknownAsyncThunkPendingAction
  | UnknownAsyncThunkRejectedAction

const serializeLoadingKey = (
  action: UnknownAsyncThunkFulfilledOrPendingAction,
  suffix: UnknownAsyncThunkFulfilledOrPendingAction['meta']['requestStatus'],
) => {
  const type = action.type.split(`/${suffix}`)[0]
  return stringify({
    arg: action.meta.arg,
    type,
  })
}

export const nexItemsSlice = createSlice({
  name: 'NexItems',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Update nexItems with live data
    builder.addCase(fetchNexItemsPublicDataAsync.fulfilled, (state, action) => {
      state.data.keycard = action.payload.keycard
      state.data.core = action.payload.core
    })

    builder.addCase(fetchNexItemsUserDataAsync.fulfilled, (state, action) => {
      state.userData.keycard = action.payload.keycard
      state.userData.core = action.payload.core
    })
  },
})

export default nexItemsSlice.reducer
