import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import Providers from './Providers'
import { ResetCSS } from '@thenexlabs/uikit'
import { PersistGate } from 'redux-persist/integration/react'
import store, { useStore, persistor } from 'state'
import { Blocklist, Updaters } from './index2'
import GlobalStyle from './style/Global'
import useEagerConnect from 'hooks/useEagerConnect'
import GlobalCheckClaimStatus from 'components/GlobalCheckClaimStatus'
import { useInactiveListener } from 'hooks/useInactiveListener'
import useSentryUser from 'hooks/useSentryUser'
import useUserAgent from 'hooks/useUserAgent'
import { usePollBlockNumber } from 'state/block/hooks'
import { usePollCoreFarmData } from 'state/farms/hooks'
import { useFetchProfile } from 'state/profile/hooks'
import { useXp } from 'state/xp/hooks'
import { useGLITCH } from 'state/glitch/hooks'
import { useNexItems } from 'state/nexItems/hooks'


function GlobalHooks() {
  usePollBlockNumber()
  useEagerConnect()
  useFetchProfile()
  usePollCoreFarmData()
  useUserAgent()
  useInactiveListener()
  useSentryUser()
  useXp()
  useNexItems()
  useGLITCH()
  return null
}

ReactDOM.render(
  <Providers store={store}>
    <Blocklist>
      <GlobalHooks />
      <Updaters />
      <ResetCSS />
      <GlobalStyle />
      <GlobalCheckClaimStatus excludeLocations={[]} />
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Blocklist>
  </Providers>
  , document.getElementById('root')
);

//
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
