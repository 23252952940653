import React, { Component } from "react";
import { Slide } from "react-awesome-reveal";

class Information extends Component {
  getRandomColor() {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  render() {
    if (!this.props.data) return null;

    const seed = this.props.data.seed.map(function (seed) {
      return (
        <div key={seed.title}>
          <h3 style={{textDecoration: 'underline'}}>{seed.title}</h3>
          <p>{seed.description}</p>
          <p className="info">
            {seed.author}{" "}
            <span>&bull;</span> <em className="date">{seed.years}</em>
          </p>
        </div>
      );
    });


    return (
      <section id="information">
        <Slide left duration={1300}>
          <div className="row seed">
            <div className="three columns header-col">
              <h1>
                <span>Seed</span>
              </h1>
            </div>

            <div className="nine columns main-col">{seed}</div>
          </div>
        </Slide>

      </section>
    );
  }
}

export default Information;
