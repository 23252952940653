import { ChainId } from '@thenexlabs/sdk'
import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'
import { VaultKey } from 'state/types'

export const getAddress = (address: Address): string => {
  const chainId = process.env.REACT_APP_PUBLIC_CHAIN_ID
  return address[chainId] ? address[chainId] : address[ChainId.AVALANCHE_MAINNET]
}


export const getBossBattlesAddress = () => {
  return getAddress(addresses.proxyBossBattles)
}
export const getCoreManagerAddress = () => {
  return getAddress(addresses.proxyCoreManager)
}
export const getGlitchAddress = () => {
  return getAddress(addresses.proxyGLITCH)
}
export const getXpUnlockWrapperAddress = () => {
  return getAddress(addresses.proxyXpUnlockWrapper)
}
export const getNexItemsAddress = () => {
  return getAddress(addresses.proxyNexItems)
}
export const getXpAddress = () => {
  return getAddress(addresses.xp)
}
export const getMasterGamerAddress = () => {
  return getAddress(addresses.masterChef)
}
export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
export const getLotteryV2Address = () => {
  return getAddress(addresses.lotteryV2)
}
export const getPancakeProfileAddress = () => {
  return getAddress(addresses.pancakeProfile)
}
// export const getPancakeRabbitsAddress = () => {
//   return getAddress(addresses.pancakeRabbits)
// }
// export const getBunnyFactoryAddress = () => {
//   return getAddress(addresses.bunnyFactory)
// }
export const getClaimRefundAddress = () => {
  return getAddress(addresses.claimRefund)
}
export const getPointCenterIgoAddress = () => {
  return getAddress(addresses.pointCenterIgo)
}
// export const getBunnySpecialAddress = () => {
//   return getAddress(addresses.bunnySpecial)
// }
// export const getTradingCompetitionAddress = () => {
//   return getAddress(addresses.tradingCompetition)
// }
// export const getTradingCompetitionAddressV2 = () => {
//   return getAddress(addresses.tradingCompetitionV2)
// }
// export const getEasterNftAddress = () => {
//   return getAddress(addresses.easterNft)
// }

export const getVaultPoolAddress = (vaultKey: VaultKey) => {
  if (!vaultKey) {
    return null
  }
  return getAddress(addresses[vaultKey])
}

export const getXpVaultAddress = () => {
  return getAddress(addresses.xpVault)
}
export const getIgoPoolAddress = () => {
  return getAddress(addresses.igoPool)
}
export const getPredictionsAddress = () => {
  return getAddress(addresses.predictions)
}
export const getChainlinkOracleAddress = () => {
  return getAddress(addresses.chainlinkOracle)
}
export const getBunnySpecialXpVaultAddress = () => {
  return getAddress(addresses.bunnySpecialXpVault)
}
export const getBunnySpecialPredictionAddress = () => {
  return getAddress(addresses.bunnySpecialPrediction)
}
export const getBunnySpecialLotteryAddress = () => {
  return getAddress(addresses.bunnySpecialLottery)
}
// export const getBunnySpecialXmasAddress = () => {
//   return getAddress(addresses.bunnySpecialXmas)
// }
export const getFarmAuctionAddress = () => {
  return getAddress(addresses.farmAuction)
}
export const getAnniversaryAchievement = () => {
  return getAddress(addresses.AnniversaryAchievement)
}
export const getNftMarketAddress = () => {
  return getAddress(addresses.nftMarket)
}
export const getNftSaleAddress = () => {
  return getAddress(addresses.nftSale)
}
export const getPancakeSquadAddress = () => {
  return getAddress(addresses.pancakeSquad)
}
