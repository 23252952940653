import React, { Controller, useWatch, useState, useEffect, useCallback } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import styled, { keyframes } from 'styled-components'
import { Flex, Heading, Button, Input } from '@thenexlabs/uikit'
import { usePriceXpBusd } from 'state/farms/hooks'
import MintAction from 'components/NexItems/MintAction'
import { useNexItemsData } from 'state/nexItems/hooks'
import { useNexItemsUserData } from 'state/nexItems/hooks'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useWeb3React } from '@web3-react/core'
import GameChainController from "./GameChainController"


const FullScreenWrapper = styled.div`
  margin: auto;
  width: 50%;
  text-align: center;
`

var leaveConfirmed = false
var confirmMessageShown = false

const startTime = Date.now()/1000
var currentTime = Date.now()/1000
var currentTimeDifference = 0

function getCurrentTime(){
  currentTime = Date.now()/1000
  currentTimeDifference = currentTime - startTime
  currentTimeDifference = currentTimeDifference.toFixed(0)
  // console.log(startTime, currentTime)
}

setInterval(() => getCurrentTime(), 1000)

const buildPaths = {
  default: {
    loaderUrl: "build/webGL/NEX_WEBGL.loader.js",
    dataUrl: "build/webGL/NEX_WEBGL.data.unityweb",
    frameworkUrl: "build/webGL/NEX_WEBGL.framework.js.unityweb",
    codeUrl: "build/webGL/NEX_WEBGL.wasm.unityweb",
    streamingAssetsUrl: "build/streamingassets-webGL",
    // loaderUrl: "build/webGL/NEX_WEBGL.loader.js",
    // dataUrl: "build/webGL/NEX_WEBGL.data"/*.unityweb"*/,
    // frameworkUrl: "build/webGL/NEX_WEBGL.framework.js"/*.unityweb"*/,
    // codeUrl: "build/webGL/NEX_WEBGL.wasm"/*.unityweb"*/,
    // streamingAssetsUrl: "build/streamingassets-webGL"
  },
  webXR: {
    // loaderUrl: "build/webXR/nex_build.loader.js",
    // dataUrl: "build/webXR/nex_build.data.unityweb",
    // frameworkUrl: "build/webXR/nex_build.framework.js.unityweb",
    // codeUrl: "build/webXR/nex_build.wasm.unityweb",
    // streamingAssetsUrl: "build/streamingassets-webXR"
    loaderUrl: "build/webGL/NEX_WEBGL.loader.js",
    dataUrl: "build/webGL/NEX_WEBGL.data.unityweb",
    frameworkUrl: "build/webGL/NEX_WEBGL.framework.js.unityweb",
    codeUrl: "build/webGL/NEX_WEBGL.wasm.unityweb",
    streamingAssetsUrl: "build/streamingassets-webGL",
  },
  // start: {
  //   loaderUrl: "build/start_build/nex_build_startscene.loader.js",
  //   dataUrl: "build/start_build/nex_build_startscene.data.unityweb",
  //   frameworkUrl: "build/start_build/nex_build_startscene.framework.js.unityweb",
  //   codeUrl: "build/start_build/nex_build_startscene.wasm.unityweb",
  //   streamingAssetsUrl: "build/start_build/streamingassets"
  // },
  // boss: {
  //   loaderUrl: "build/boss_build/nex_build_bossscene.loader.js",
  //   dataUrl: "build/boss_build/nex_build_bossscene.data.unityweb",
  //   frameworkUrl: "build/boss_build/nex_build_bossscene.framework.js.unityweb",
  //   codeUrl: "build/boss_build/nex_build_bossscene.wasm.unityweb",
  //   streamingAssetsUrl: "build/boss_build/streamingassets"
  // },
  // portals: {
  //   loaderUrl: "build/portals_build/nex_build.loader.js",
  //   dataUrl: "build/nex_build.data.unityweb",
  //   frameworkUrl: "build/nex_build.framework.js.unityweb",
  //   codeUrl: "build/nex_build.wasm.unityweb",
  //   streamingAssetsUrl: "build/streamingassets"
  // }
}


var gameReady = false;

const UnityWindow: React.FC<{
  updateBuild: any, 
  gameState: string,
  playerName: string,
  setPlayerName: any
}> = (props) => {
  const buildState = props.gameState

  var {
    loadingProgression,
    unityProvider,
    isLoaded,
    requestFullscreen,
    requestPointerLock,
    sendMessage,
    initialisationError,
    addEventListener,
    removeEventListener,
    takeScreenshot,
    unload,
    UNSAFE__unityInstance,
  } = useUnityContext({
    productName: "The NEX",
    companyName: "NEX Level Gaming",
    loaderUrl: buildPaths[buildState].loaderUrl,
    dataUrl: buildPaths[buildState].dataUrl,
    frameworkUrl: buildPaths[buildState].frameworkUrl,
    codeUrl: buildPaths[buildState].codeUrl,
    streamingAssetsUrl: buildPaths[buildState].streamingAssetsUrl,
    webglContextAttributes: {
      alpha: true,
      antialias: true,
      depth: true,
      failIfMajorPerformanceCaveat: true,
      powerPreference: "high-performance",
      premultipliedAlpha: true,
      preserveDrawingBuffer: true,
      stencil: true,
      desynchronized: true,
      xrCompatible: true,
    },
  });

  function handleGameReady() {
    // console.log('gameReady')
    setPlayerName();
    // set player Nex items
    // set player data sticks
    // set player equipped items
    gameReady = true;
  };

  useEffect(() => {
    addEventListener("GameReady", handleGameReady);
    // useEffect clean up
    return () => {
      removeEventListener("GameReady", handleGameReady);
    };
  }, [addEventListener, removeEventListener, handleGameReady]);

  function setPlayerName() {
    // const name = document.getElementById('player-name')
    sendMessage("SceneManager", "SetPlayerName", '0n');
  }

  async function handleUpdateGameState(state) {
    if(isLoaded){
      await unload();
    }
    props.updateBuild(state);
  }

  function handleOnClickFullscreen() {
    requestFullscreen(true)
  }

  async function toggleWebGL () {
    if(props.gameState != 'default') {
      await handleUpdateGameState('default') 
    }
  };

  async function toggleWebXR () {
    if(props.gameState != 'webXR') {
      await handleUpdateGameState('webXR') 
    }
  };

  function enterVR () {
    if(UNSAFE__unityInstance) {
      UNSAFE__unityInstance.Module.WebXR.toggleVR();
    }
  };

  const windowWidth = window.innerWidth*5/6;
  const windowHeight = windowWidth*3/4 - windowWidth*1/5;

  const {
    core, 
    keycard
  } = useNexItemsData()
  const {
    core: coreUser,
    keycard: keycardUser
  } = useNexItemsUserData()

  const maxSupply = core.maxSupply.toNumber();
  const mintedSupply = core.totalSupply.toNumber();
  const maxMintPerUser = 2;
  const saleState = core.saleState.toNumber();
  const isPausedSale = saleState === 0;
  const isWhitelistSale = saleState === 1;
  const isPublicSale = saleState === 2;
  const userMintedCore = coreUser.numMinted.toNumber();
  const userBalanceCore = coreUser.balance.toNumber();
  const userMintedKeycard = keycardUser.numMinted.toNumber();
  const userBalanceKeycard = keycardUser.balance.toNumber();
  const _mintAmount = '1'
  const xpPriceUsd = usePriceXpBusd()
  // console.log(userBalanceCore, userBalanceKeycard)
  const loadingDisplay = isLoaded ? 
  "" 
  : 
  <div style={{width: '200px', height: '10px', border: '1px solid white'}}>
    <div style={{background: '#39eb13', height: '100%', width: loadingProgression*100+'%'}}></div>
  </div>


  const timerDisplay = ""//isLoaded ? "" : `${currentTimeDifference>=60 ? `${(currentTimeDifference/60).toFixed(0)} min ${ Math.abs((((currentTimeDifference/60).toFixed(2) - (currentTimeDifference/60).toFixed(0)) * 60).toFixed(0)) } sec` : `${currentTimeDifference} sec`}`

  const noteDisplay = "The NEX may take up to a few minutes to load if it's your first time, please be patient while world data is saved to your cache."

  // const onChangeHandler = (e: React.FormEvent<HTMLInputElement>) => {
  //   console.log(e)
  //   props.setPlayerName(e.currentTarget.value)
  // }

  const { account } = useWeb3React()


  return (
    <Flex
      position="relative"
      flexDirection={['column-reverse']}
      alignItems={['center']}
      justifyContent="center"
      id="world-hub"
    >
      <Flex flex="1" flexDirection="column" mt="50px" mb="150px">
        {/*isLoaded && !gameReady && <Input 
          id="player-name" 
          placeholder="Player Name" 
          type="text"
          value={props.playerName}
          onChange={e=>{
            console.log(e)
            props.setPlayerName(e.target.value)
          }}
          style={{width: '200px', margin: '10px auto', textAlign: 'center'}}
        />*/}
        {/*!account?
          <FullScreenWrapper>
            <ConnectWalletButton/>
          </FullScreenWrapper>
          :
          null
        */}
        <div id="unitywindow">
          {!isLoaded && (
            // We'll conditionally render the loading overlay if the Unity
            // Application is not loaded.
            <>
              {/*
              <div style={{
                position: 'absolute',
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                margin: "auto",
                marginTop: '20px'
              }}>
                <p style={{
                  color: "#39eb13"
                }}>{timerDisplay}</p>
              </div>
              */}
              <div style={{
                position: 'absolute',
                width: windowWidth,
                height: windowHeight
              }}>
                <img style={{
                  objectFit: "cover",
                  width: "100%",
                  height: "100%"
                }} src={'./loading/Beware_of_GLITCH.gif'} />
              </div>
              <div style={{
                position: 'absolute',
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                top: "20vh",
                left: "0px",
                width: "100%",
                height: "50%",
                margin: "auto"
              }}>
                {loadingDisplay}
              </div>
            </>
          )}
          <Unity
            unityProvider={unityProvider}
            style={{ margin: "0 auto", border: "1px solid white", height: windowHeight, width: windowWidth }}
          />
          {isLoaded ? 
            <>
              <FullScreenWrapper>
                <button onClick={handleOnClickFullscreen}><b>Fullscreen</b></button>
              </FullScreenWrapper>
              <GameChainController/>
              {props.gameState == 'webXR' && (
                <div style={{
                  position: 'absolute',
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  top: "20vh",
                  left: "0px",
                  width: "100%",
                  height: "50%",
                  margin: "auto"
                }}> 
                  <button id="entervr" onClick={enterVR}><b>Immersive VR</b></button>
                </div>
              )}
            </>
            :
            <p style={{
              color: "#39eb13", margin: "10px 100px", textAlign: 'center'
            }}>{noteDisplay}</p>
          }
          <FullScreenWrapper>
            The NEX World Hub is currently in 
            {props.gameState == 'default' && 
              <p style={{
                color: "#39eb13"
              }}><b>Browser Mode</b></p>
            }
            {props.gameState == 'webXR' && 
              <p style={{
                color: "#39eb13"
              }}><b>WebXR Mode</b></p>
            }
            {isLoaded &&
              <>
                {props.gameState == 'default'? 
                  <button 
                    onClick={toggleWebXR}
                  ><b>Load WebXR Mode</b></button>
                  :
                  <button 
                    onClick={toggleWebGL}
                  ><b>Load Browser Mode</b></button>
                }
              </>
            }
          </FullScreenWrapper>
        </div>
      </Flex>
    </Flex>
  )
}

export default UnityWindow
