import React from 'react'
import { Flex, IconButton, CogIcon, useModal } from '@thenexlabs/uikit'
import SettingsModal from './SettingsModal'

type Props = {
  color?: string
  mr?: string
}

const GlobalSettings = ({ color, mr = '8px' }: Props) => {
  const [onPresentSettingsModal] = useModal(<SettingsModal />)

  return (
    <Flex>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton style={{margin: '0 5px', paddingLeft: '0'}} onClick={onPresentSettingsModal} variant="text" scale="sm" mr={mr} id="open-settings-dialog-button">
          <CogIcon height={24} width={24} color={color || 'textSubtle'} />
        </IconButton>
      </div>
    </Flex>
  )
}

export default GlobalSettings
