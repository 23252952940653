import React, { Component } from "react";
import { Slide } from "react-awesome-reveal";

class Controls extends Component {
  getRandomColor() {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  render() {
    if (!this.props.data) return null;

    const controlmessage = this.props.data.controlmessage;


    const controls = this.props.data.controls.map((controls) => {
      const backgroundColor = "#39eb13"//this.getRandomColor();

      return (
        <li key={controls.name}>
          <em>{controls.name}</em>
          <em style={{color: backgroundColor}}>{controls.input}</em>
        </li>
      );
    });

    return (
      <section id="controls">

        <Slide left duration={1300}>
          <div className="row control">
            <div className="three columns header-col">
              <h1>
                  <span>Controls</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              <p>{controlmessage}</p>

              <div className="bars">
                <ul className="controls">{controls}</ul>
              </div>
            </div>
          </div>
        </Slide>
      </section>
    );
  }
}

export default Controls;
