import React, { Controller, useWatch, useState, useEffect, useCallback } from "react";
import styled, { keyframes } from 'styled-components'
import { Flex, Heading, Button, Input } from '@thenexlabs/uikit'
import { usePriceXpBusd } from 'state/farms/hooks'
import MintAction from 'components/NexItems/MintAction'
import { useNexItemsData } from 'state/nexItems/hooks'
import { useNexItemsUserData } from 'state/nexItems/hooks'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useWeb3React } from '@web3-react/core'
import GameTransactionModal from 'components/Game/GameTransactionModal'

const FullScreenWrapper = styled.div`
  margin: auto;
  width: 50%;
  text-align: center;
`

var leaveConfirmed = false
var confirmMessageShown = false

const startTime = Date.now()/1000
var currentTime = Date.now()/1000
var currentTimeDifference = 0

function getCurrentTime(){
  currentTime = Date.now()/1000
  currentTimeDifference = currentTime - startTime
  currentTimeDifference = currentTimeDifference.toFixed(0)
  // console.log(startTime, currentTime)
}

setInterval(() => getCurrentTime(), 1000)


var gameReady = false;

const GameChainController: React.FC<{
  updateBuild: any, 
  gameState: string,
  playerName: string,
  setPlayerName: any
}> = (props) => {
  

  

  const windowWidth = window.innerWidth*5/6;
  const windowHeight = windowWidth*3/4 - windowWidth*1/5;

  const {
    core, 
    keycard
  } = useNexItemsData()
  const {
    core: coreUser,
    keycard: keycardUser
  } = useNexItemsUserData()

  const maxSupply = core.maxSupply.toNumber();
  const mintedSupply = core.totalSupply.toNumber();
  const maxMintPerUser = 2;
  const saleState = core.saleState.toNumber();
  const isPausedSale = saleState === 0;
  const isWhitelistSale = saleState === 1;
  const isPublicSale = saleState === 2;
  const userMintedCore = coreUser.numMinted.toNumber();
  const userBalanceCore = coreUser.balance.toNumber();
  const userMintedKeycard = keycardUser.numMinted.toNumber();
  const userBalanceKeycard = keycardUser.balance.toNumber();
  const _mintAmount = '1'
  const xpPriceUsd = usePriceXpBusd()
 


  const timerDisplay = ""//isLoaded ? "" : `${currentTimeDifference>=60 ? `${(currentTimeDifference/60).toFixed(0)} min ${ Math.abs((((currentTimeDifference/60).toFixed(2) - (currentTimeDifference/60).toFixed(0)) * 60).toFixed(0)) } sec` : `${currentTimeDifference} sec`}`

  const noteDisplay = "The NEX may take up to a few minutes to load if it's your first time, please be patient while world data is saved to your cache."

  // const onChangeHandler = (e: React.FormEvent<HTMLInputElement>) => {
  //   console.log(e)
  //   props.setPlayerName(e.currentTarget.value)
  // }

  const { account } = useWeb3React()


  return (
    <Flex
      position="relative"
      flexDirection={['column-reverse']}
      alignItems={['center']}
      justifyContent="center"
      id="world-hub"
    >
      {/*
      <GameTransactionModal onDismiss={}/>
      */}
    </Flex>
  )
}

export default GameChainController
