import { Token, ChainId } from '@thenexlabs/sdk'
import tokens from './tokens'
import farms from './farms'
import { Igo } from './types'

export const xpBnbLpToken = new Token(
  process.env.REACT_APP_PUBLIC_CHAIN_ID as unknown as ChainId,
  farms[1].lpAddresses[process.env.REACT_APP_PUBLIC_CHAIN_ID],
  18,
  farms[1].lpSymbol
)

const ifos: Igo[] = [
  // {
  //   id: 'experience',
  //   address: '0xE0d6c91860a332068bdB59275b0AAC8769e26Ac4',
  //   isActive: true,
  //   name: 'Froyo Games (FROYO)',
  //   poolBasic: {
  //     saleAmount: '20,000,000 FROYO',
  //     raiseAmount: '$1,200,000',
  //     xpToBurn: '$0',
  //     distributionRatio: 0.3,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '46,666,668 FROYO',
  //     raiseAmount: '$2,800,000',
  //     xpToBurn: '$0',
  //     distributionRatio: 0.7,
  //   },
  //   currency: tokens.wavax,
  //   token: tokens.xp,
  //   releaseBlockNumber: 14297000,
  //   campaignId: '511170000',
  //   articleUrl: 'https://thenex.world/voting/proposal/QmRhc4oC73jk4zxU4YkP1kudKHeq6qamgYA1sWoh6XJnks',
  //   tokenOfferingPrice: 0.06,
  //   version: 3,
  //   telegramUrl: 'https://t.me/froyogames',
  //   twitterUrl: 'https://twitter.com/realfroyogames',
  //   description: `Froyo Games is a game publisher and decentralized GameFi platform, with a NFT Marketplace that integrates NFTs with their games.\n \n FROYO tokens can be used to buy NFTs and participate in Froyo games`,
  // }//,
  // {
  //   id: 'ditto',
  //   address: '0x570c9eB19553526Fb35895a531928E19C7D20788',
  //   isActive: false,
  //   name: 'Ditto (DITTO)',
  //   poolUnlimited: {
  //     saleAmount: '700,000 DITTO',
  //     raiseAmount: '$630,000',
  //     xpToBurn: '$315,000',
  //     distributionRatio: 1,
  //   },
  //   currency: xpOneLpToken,
  //   token: tokens.xp,
  //   releaseBlockNumber: 3279767,
  //   campaignId: '511020000',
  //   articleUrl: 'https://pancakeswap.medium.com/ditto-money-ditto-ifo-to-be-hosted-on-pancakeswap-342da3059a66',
  //   tokenOfferingPrice: null,
  //   version: 1,
  // }
]

export default ifos
