import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import nexItemsABI from 'config/abi/nexItems.json'
import multicall from 'utils/multicall'
import { getNexItemsAddress } from 'utils/addressHelpers'

export const fetchPublicNexItems = async () => {
  const nexItemsAddress = getNexItemsAddress()
  const nexItemId = '0'

  //keycards
  const callTotalSupply = {
    address: nexItemsAddress,
    name: 'totalSupply',
    params: [nexItemId]
  }

  const callSaleState = {
    address: nexItemsAddress,
    name: 'saleStateMap',
    params: [nexItemId]
  }

  const callMaxSupply = {
    address: nexItemsAddress,
    name: 'maxSupplyMap',
    params: [nexItemId]
  }

  //core
  const callTotalSupplyCore = {
    address: nexItemsAddress,
    name: 'totalSupply',
    params: ['1']
  }

  const callSaleStateCore = {
    address: nexItemsAddress,
    name: 'saleStateMap',
    params: ['1']
  }

  const callMaxSupplyCore = {
    address: nexItemsAddress,
    name: 'maxSupplyMap',
    params: ['1']
  }


  const calls = [
    callTotalSupply,
    callSaleState,
    callMaxSupply,
    callTotalSupplyCore,
    callSaleStateCore,
    callMaxSupplyCore
  ]


  const rawNexItemsAmounts = await multicall<BigNumber[]>(nexItemsABI, calls)

  const parsedAmounts = rawNexItemsAmounts.map((nexItemsBalance) => {
    return new BigNumber(nexItemsBalance).toJSON()
  })

  return {
    keycard: {
      totalSupply: parsedAmounts[0],
      saleState: parsedAmounts[1],
      maxSupply: parsedAmounts[2]
    },
    core: {
      totalSupply: parsedAmounts[3],
      saleState: parsedAmounts[4],
      maxSupply: parsedAmounts[5]
    }
  }
}
