import { ChainId, Token } from '@thenexlabs/sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { SerializedToken } from './types'

const { AVALANCHE_MAINNET, AVALANCHE_TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

const defineTokens = <T extends TokenList>(t: T) => t

export const mainnetTokens = defineTokens({
  wavax: new Token( // wone
    AVALANCHE_MAINNET,
    '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    18,
    'WAVAX',
    'Wrapped AVAX',
    'https://snowtrace.io/',
  ),
  // avax (one) here points to the wavax contract. Wherever the currency AVAX is required, conditional checks for the symbol 'AVAX' can be used
  avax: new Token(
    AVALANCHE_MAINNET,
    '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    18,
    'AVAX',
    'AVAX',
    'https://snowtrace.io/'
  ),
  xp: new Token(
    AVALANCHE_MAINNET,
    '0x0238F2e8D761FB14181501ab96A123a1488CE2c8',
    18,
    'XP',
    'Experience Token',
    'https://thenex.world/',
  ),
  boost: new Token(
    AVALANCHE_MAINNET,
    '0x543661FbB6b2096FAb5B5e4fEE23Fd69b1d4A74a',
    18,
    'BOOST',
    'BoostBar Token',
    'https://thenex.world/',
  ),
  usdc: new Token(
    AVALANCHE_MAINNET,
    '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
    6,
    'USDC',
    'USDC',
    'https://www.paxos.com/usdc/',
  ),
  // dai: new Token(
  //   AVALANCHE_MAINNET,
  //   '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
  //   18,
  //   'DAI',
  //   'Dai Stablecoin',
  //   'https://www.makerdao.com/',
  // ),
  // usdt: new Token(
  //   AVALANCHE_MAINNET,
  //   '0x55d398326f99059fF775485246999027B3197955',
  //   18,
  //   'USDT',
  //   'Tether USD',
  //   'https://tether.to/',
  // ),
  // btc: new Token(
  //   AVALANCHE_MAINNET,
  //   '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   18,
  //   '1BTC',
  //   'BTC',
  //   'https://bitcoin.org/',
  // ),
  // eth: new Token(
  //   AVALANCHE_MAINNET,
  //   '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  //   18,
  //   '1ETH',
  //   'Ethereum',
  //   'https://ethereum.org/en/',
  // ),
} as const)

export const testnetTokens = defineTokens({
  wavax: new Token(
    AVALANCHE_TESTNET,
    '0xc865fD97b266f0aAEAd6A69381D2E9D049Daf2Cd',
    18,
    'WAVAX',
    'Wrapped AVAX',
    'https://testnet.snowtrace.io/',
  ),
  avax: new Token(
    AVALANCHE_TESTNET,
    '0xc865fD97b266f0aAEAd6A69381D2E9D049Daf2Cd',
    18,
    'AVAX',
    'AVAX',
    'https://testnet.snowtrace.io/'
  ),
  xp: new Token(
    AVALANCHE_TESTNET,
    '0xFa647818143Cd033140413951a107b0c091a174c',
    18,
    'XP',
    'XP Token',
    'https://thenex.world/',
  ),
  usdc: new Token(
    AVALANCHE_TESTNET,
    '0xeE96f04855AE8558f1B81a4657FD3Dd556837e4B',
    18,
    'USDC',
    'USDC',
    'https://www.paxos.com/usdc/',
  ),
  boost: new Token(
    AVALANCHE_TESTNET,
    '0x4711b86FA1f884aAD5CC0C64cD2Ff93BDdf4298A',
    18,
    'BOOST',
    'BoostBar Token',
    'https://thenex.world/',
  ),
  // bake: new Token(
  //   AVALANCHE_TESTNET,
  //   '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
  //   18,
  //   'BAKE',
  //   'Bakeryswap Token',
  //   'https://www.bakeryswap.org/',
  // ),
} as const)

const tokens = () => {
  const chainId = process.env.REACT_APP_PUBLIC_CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.AVALANCHE_TESTNET) {
    return Object.keys(mainnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    }, {} as typeof testnetTokens & typeof mainnetTokens)
  }

  return mainnetTokens
}

const unserializedTokens = tokens()

type SerializedTokenList = Record<keyof typeof unserializedTokens, SerializedToken>

export const serializeTokens = () => {
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {} as SerializedTokenList)

  return serializedTokens
}

export default unserializedTokens
