import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { useFastRefreshEffect, useSlowRefreshEffect } from 'hooks/useRefreshEffect'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { deserializeToken } from 'state/user/hooks/helpers'
import { BIG_ZERO } from 'utils/bigNumber'
import { getBalanceAmount } from 'utils/formatBalance'
import { fetchNexItemsPublicDataAsync, fetchNexItemsUserDataAsync } from '.'
import { DeserializedNexItems, DeserializedNexItemsUserData, DeserializedNexItemsState, SerializedNexItems, SerializedNexItemsUserData, State } from '../types'

const deserializeNexItemsData = (data: SerializedNexItems): DeserializedNexItems => {
  return {
    keycard: {
      totalSupply: data.keycard.totalSupply ? new BigNumber(data.keycard.totalSupply) : BIG_ZERO,
      saleState: data.keycard.saleState ? new BigNumber(data.keycard.saleState) : BIG_ZERO,
      maxSupply: data.keycard.maxSupply ? new BigNumber(data.keycard.maxSupply) : BIG_ZERO,
    },
    core: {
      totalSupply: data.core.totalSupply ? new BigNumber(data.core.totalSupply) : BIG_ZERO,
      saleState: data.core.saleState ? new BigNumber(data.core.saleState) : BIG_ZERO,
      maxSupply: data.core.maxSupply ? new BigNumber(data.core.maxSupply) : BIG_ZERO,
    }
  }
}

const deserializeNexItemsUserData = (data: SerializedNexItemsUserData): DeserializedNexItemsUserData => {
  return {
    keycard: {
      numMinted: data.keycard.numMinted ? new BigNumber(data.keycard.numMinted) : BIG_ZERO,
      balance: data.keycard.balance ? new BigNumber(data.keycard.balance) : BIG_ZERO
    },
    core: {
      numMinted: data.core.numMinted ? new BigNumber(data.core.numMinted) : BIG_ZERO,
      balance: data.core.balance ? new BigNumber(data.core.balance) : BIG_ZERO
    }
  }
}

export const useNexItems = () => {
  const dispatch = useAppDispatch()
  const { account } = useActiveWeb3React()

  useFastRefreshEffect(() => {
    dispatch(fetchNexItemsPublicDataAsync(''))
    dispatch(fetchNexItemsUserDataAsync(account))
  }, [account, dispatch])
}

export const useNexItemsData = (): DeserializedNexItems => {
  const nexItemsData = useSelector((state: State) => state.nexItems.data)

  if(!nexItemsData){
    return {
      keycard: {
        totalSupply: new BigNumber('0'),
        saleState: new BigNumber('0'),
        maxSupply: new BigNumber('0')
      },
      core: {
        totalSupply: new BigNumber('0'),
        saleState: new BigNumber('0'),
        maxSupply: new BigNumber('0')
      }
    }
  }

  const deserializedNexItemsData: DeserializedNexItems = deserializeNexItemsData(nexItemsData)

  return deserializeNexItemsData(nexItemsData)
}

export const useNexItemsUserData = (): DeserializedNexItemsUserData => {
  const nexItemsUserData = useSelector((state: State) => state.nexItems.userData)

  if(!nexItemsUserData){
    return {
      keycard: {
        numMinted: new BigNumber('0'),
        balance: new BigNumber('0')
      },
      core: {
        numMinted: new BigNumber('0'),
        balance: new BigNumber('0')
      }
    }
  }

  const deserializedNexItemsUserData: DeserializedNexItemsUserData = deserializeNexItemsUserData(nexItemsUserData)

  return deserializeNexItemsUserData(nexItemsUserData)
}
