import React, { useState, useRef, useEffect, useCallback } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import styled, { keyframes } from 'styled-components'
import { Flex, Heading, Button, Input } from '@thenexlabs/uikit'
import { usePriceXpBusd } from 'state/farms/hooks'
import MintAction from 'components/NexItems/MintAction'
import { useNexItemsData } from 'state/nexItems/hooks'
import { useNexItemsUserData } from 'state/nexItems/hooks'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useWeb3React } from '@web3-react/core'
import GameChainController from "./GameChainController"


const FullScreenWrapper = styled.div`
  margin: auto;
  width: 50%;
  text-align: center;
`

var leaveConfirmed = false
var confirmMessageShown = false

const startTime = Date.now()/1000
var currentTime = Date.now()/1000
var currentTimeDifference = 0

function getCurrentTime(){
  currentTime = Date.now()/1000
  currentTimeDifference = currentTime - startTime
  currentTimeDifference = currentTimeDifference.toFixed(0)
  // console.log(startTime, currentTime)
}

setInterval(() => getCurrentTime(), 1000)

const buildPaths = {
  default: {
    loaderUrl: "build/webXR-template/Build.loader.js",
    dataUrl: "build/webXR-template/Build.data",
    frameworkUrl: "build/webXR-template/Build.framework.js",
    codeUrl: "build/webXR-template/Build.wasm"
  }
}


var gameReady = false;

const UnityWindow: React.FC<> = (props) => {
  const buildState = 'default'

  var {
    loadingProgression,
    unityProvider,
    isLoaded,
    requestFullscreen,
    requestPointerLock,
    sendMessage,
    initialisationError,
    addEventListener,
    removeEventListener,
    takeScreenshot,
    unload,
    UNSAFE__unityInstance,
  } = useUnityContext({
    productName: "NEX World Hub",
    companyName: "The NEX",
    loaderUrl: buildPaths[buildState].loaderUrl,
    dataUrl: buildPaths[buildState].dataUrl,
    frameworkUrl: buildPaths[buildState].frameworkUrl,
    codeUrl: buildPaths[buildState].codeUrl,
    streamingAssetsUrl: buildPaths[buildState].streamingAssetsUrl,
    webglContextAttributes: {
      alpha: true,
      antialias: true,
      depth: true,
      failIfMajorPerformanceCaveat: true,
      powerPreference: "high-performance",
      premultipliedAlpha: true,
      preserveDrawingBuffer: true,
      stencil: true,
      desynchronized: true,
      xrCompatible: true,
    },
  });
  
  function handleOnClickFullscreen() {
    requestFullscreen(true)
  }

  function enterVR () {
    if(UNSAFE__unityInstance) {
      UNSAFE__unityInstance.Module.WebXR.toggleVR();
    }
  };

  const windowWidth = window.innerWidth*5/6;
  const windowHeight = windowWidth*3/4 - windowWidth*1/5;

  const {
    core, 
    keycard
  } = useNexItemsData()
  const {
    core: coreUser,
    keycard: keycardUser
  } = useNexItemsUserData()

  const maxSupply = core.maxSupply.toNumber();
  const mintedSupply = core.totalSupply.toNumber();
  const maxMintPerUser = 2;
  const saleState = core.saleState.toNumber();
  const isPausedSale = saleState === 0;
  const isWhitelistSale = saleState === 1;
  const isPublicSale = saleState === 2;
  const userMintedCore = coreUser.numMinted.toNumber();
  const userBalanceCore = coreUser.balance.toNumber();
  const userMintedKeycard = keycardUser.numMinted.toNumber();
  const userBalanceKeycard = keycardUser.balance.toNumber();
  const _mintAmount = '1'
  const xpPriceUsd = usePriceXpBusd()
  // console.log(userBalanceCore, userBalanceKeycard)
  const loadingDisplay = isLoaded ? 
  "" 
  : 
  <div style={{width: '200px', height: '10px', border: '1px solid white'}}>
    <div style={{background: '#39eb13', height: '100%', width: loadingProgression*100+'%'}}></div>
  </div>


  const timerDisplay = ""//isLoaded ? "" : `${currentTimeDifference>=60 ? `${(currentTimeDifference/60).toFixed(0)} min ${ Math.abs((((currentTimeDifference/60).toFixed(2) - (currentTimeDifference/60).toFixed(0)) * 60).toFixed(0)) } sec` : `${currentTimeDifference} sec`}`

  
  // const onChangeHandler = (e: React.FormEvent<HTMLInputElement>) => {
  //   console.log(e)
  //   props.setPlayerName(e.currentTarget.value)
  // }

  const { account } = useWeb3React()


  return (
    <Flex
      position="relative"
      flexDirection={['column-reverse']}
      alignItems={['center']}
      justifyContent="center"
      id="world-hub"
    >
      <Flex flex="1" flexDirection="column" mt="50px" mb="150px">
        <div id="unitywindow">
            {!isLoaded && (
              <>
                {/*
                <div style={{
                  position: 'absolute',
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  margin: "auto",
                  marginTop: '20px'
                }}>
                  <p style={{
                    color: "#39eb13"
                  }}>{timerDisplay}</p>
                </div>*/}
                <div style={{
                  position: 'absolute',
                  width: windowWidth,
                  height: windowHeight
                }}>
                  <img style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%"
                  }} src={'./loading/Beware_of_GLITCH.gif'} />
                </div>
                <div style={{
                  position: 'absolute',
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  top: "0px",
                  left: "0px",
                  width: "100%",
                  height: "100%",
                  margin: "auto"
                }}>
                  {loadingDisplay}
                </div>
              </>
            )}
            <Unity
              unityProvider={unityProvider}
              style={{ margin: "0 auto", border: "1px solid white", height: windowHeight, width: windowWidth }}
            />
            {isLoaded && 
              <>
                <FullScreenWrapper>
                  <button onClick={handleOnClickFullscreen}><b>Fullscreen</b></button>
                  <button id="entervr" onClick={enterVR}><b>Immersive VR</b></button>
                </FullScreenWrapper>
                <GameChainController/>
              </>
            }
        </div>
      </Flex>
    </Flex>
  )
}

export default UnityWindow
