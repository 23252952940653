import { useEffect } from 'react';

interface AdComponentProps {
  isFreeVersion: boolean;
}

const AdComponent: React.FC<AdComponentProps> = ({ isFreeVersion }) => {
  useEffect(() => {
    if (isFreeVersion) {
      const script = document.createElement('script');
      script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5868803777621110';
      script.async = true;
      script.crossOrigin = 'anonymous';
      document.body.appendChild(script);
    }
  }, [isFreeVersion]);

  return (
    <div>
      {isFreeVersion && (
        <ins
          className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-5868803777621110"
          data-ad-slot="1234567890"
          data-ad-format="auto"
        ></ins>
      )}
    </div>
  );
};

export default AdComponent;
