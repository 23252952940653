import { MenuItemsType, DropdownMenuItemType } from '@thenexlabs/uikit'
import { ContextApi } from 'contexts/Localization/types'
// import { nftsBaseUrl } from 'views/Nft/market/constants'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [
  // {
  //   label: t('Web4'),
  //   icon: 'Web4',
  //   href: '/web4',
  //   items: [
  //     {
  //       label: t('What is Web4?'),
  //       href: '/web4',
  //     },
  //     {
  //       label: t('First Web4 Network Design'),
  //       href: 'https://thenex.world/documents/The_NEX_Network_Whitepaper.pdf',
  //     },
  //   ],
  // },
  {
    label: t('WebXR'),
    icon: 'WebXR',
    href: '/webXR',
    showItemsOnMobile: false,
    items: [
      {
        label: t('What is WebXR?'),
        href: '/webXR',
      },
    ],
  }
  // {
  //   label: t('Play'),
  //   icon: 'Play',
  //   href: '/hub',
  //   showItemsOnMobile: false,
  //   items: [
  //     {
  //       label: t('The NEX World Hub'),
  //       href: '/hub',
  //     },
  //     /*{
  //       label: t('IGOs'),
  //       href: '/igo',
  //     },
  //     {
  //       label: t('Portals'),
  //       href: '/portals',
  //     },*/
  //   ],
  // },
  // {
  //   label: t('NFTs'),
  //   icon: 'Nft',
  //   href: '/glitch',
  //   items: [
  //     {
  //       label: t('Cores'),
  //       href: '/core',
  //     },
  //     {
  //       label: t('GLITCH'),
  //       href: '/glitch',
  //     },
  //     {
  //       label: t('Keycards'),
  //       href: '/keycard',
  //     },
  //   ],
  // },/*
  // {
  //   label: t('Games'),
  //   icon: 'Nft',
  //   href: '/games',
  //   items: [
  //     {
  //       label: t('Games'),
  //       href: '/games',
  //     },
  //     {
  //       label: t('Studios'),
  //       href: '/studios',
  //     },
  //   ],
  // },
  // {
  //   label: t('Win'),
  //   icon: 'Trophy',
  //   href: '/prediction',
  //   items: [
  //     {
  //       label: t('Trading Competition'),
  //       href: '/competition',
  //     },
  //     {
  //       label: t('Prediction (BETA)'),
  //       href: '/prediction',
  //     },
  //     {
  //       label: t('Lottery'),
  //       href: '/lottery',
  //     },
  //   ],
  // },
  // {
  //   label: t('NFT'),
  //   icon: 'Nft',
  //   href: `${nftsBaseUrl}`,
  //   items: [
  //     {
  //       label: t('Overview'),
  //       href: `${nftsBaseUrl}`,
  //     },
  //     {
  //       label: t('Collections'),
  //       href: `${nftsBaseUrl}/collections`,
  //     },
  //     {
  //       label: t('Activity'),
  //       href: `${nftsBaseUrl}/activity`,
  //     },
  //   ],
  // },
  // {
  //   label: '',
  //   icon: 'More',
  //   href: '/info',
  //   hideSubNav: true,
  //   items: [
  //     {
  //       label: t('Info'),
  //       href: '/info',
  //     },
  //     {
  //       label: t('IFO'),
  //       href: '/ifo',
  //     },
  //     {
  //       label: t('Voting'),
  //       href: '/voting',
  //     },
  //     {
  //       type: DropdownMenuItemType.DIVIDER,
  //     },
  //     {
  //       label: t('Leaderboard'),
  //       href: '/teams',
  //     },
  //     {
  //       type: DropdownMenuItemType.DIVIDER,
  //     },
  //     {
  //       label: t('Blog'),
  //       href: 'https://medium.com/pancakeswap',
  //       type: DropdownMenuItemType.EXTERNAL_LINK,
  //     },
  //     {
  //       label: t('Docs'),
  //       href: 'https://docs.thenex.world',
  //       type: DropdownMenuItemType.EXTERNAL_LINK,
  //     },
  //   ],
  // }, */
]

export default config
