import React, { Component, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import ReactGA from "react-ga";
import $ from "jquery";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Information from "./components/Information";
import Controls from "./components/Controls";
import UnityWindow from "./components/UnityWindow";
import About from "./components/About";
import Resume from "./components/Resume";
import Contact from "./components/Contact";
import Portfolio from "./components/Portfolio";
import Loading from "./components/Loading";
import ReactAudioPlayer from 'react-audio-player';
import Menu from './components/Menu'
import ErrorBoundary from './components/ErrorBoundary'
import BigNumber from 'bignumber.js'
import EasterEgg from 'components/EasterEgg'
import { ToastListener } from 'contexts/ToastsContext'
import SubgraphHealthIndicator from 'components/SubgraphHealthIndicator'
import AdComponent from 'components/AdComponent'

import HomePage from 'pages/HomePage'
import WebXRPage from 'pages/WebXRPage'


export const App = () => {
  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     appData: {},
  //     isLoading: true,
  //     isAudioPlaying: false,
  //     store
  //   };

  //   this.audio = new Audio('sfx/NEXus.mp3')

  //   this.load = this.load.bind(this)
  //   this.playAudio = this.playAudio.bind(this)
  //   // ReactGA.initialize("UA-110570651-1");
  //   // ReactGA.pageview(window.location.pathname);
  // }

  // getAppData() {
  //   $.ajax({
  //     url: "./appData.json",
  //     dataType: "json",
  //     cache: false,
  //     success: function(data) {
  //       this.setState({ appData: data });
  //     }.bind(this),
  //     error: function(xhr, status, err) {
  //       console.log(err);
  //       alert(err);
  //     }
  //   });
  // }

  // load(){
  //   this.setState({isLoading: false}, this.playAudio() )
  // }

  // playAudio(){
  //   document.getElementById('app-audio').play();
  //   // this.rap.audioEl
  //   // this.setState({
  //   //   isAudioPlaying: true
  //   // })
  //   // this.audio.play();
  //   // console.log(this.rap.audioEl)
  //   // this.audio.volume = 0.1
  // }

  // componentDidMount() {
  //   this.getAppData();
  // }

  // render() {
    return (
      <ErrorBoundary>
        <Menu>
          <AdComponent isFreeVersion={true} />
          <div className="App" style={/*this.state.isLoading?{height: '90vh', overflow: 'hidden'}:*/{height: '100%'}}>
            <Router>
              <Switch>
                <Route exact path="/" component={HomePage} />
                <Route path="/webXR" component={WebXRPage} />
              </Switch>
            </Router>
            {/*
            <About data={this.state.appData.main} />
            <Resume data={this.state.appData.resume} />
            <Portfolio data={this.state.appData.portfolio} />
            <Contact data={this.state.appData.main} />
            */}
            {/*<div style={{
              position: 'absolute', 
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bottom: 0
            }}>
              <ReactAudioPlayer
                id='app-audio'
                src="sfx/NEXus.mp3"
                controls
                volume={0.3}
                loop={true}
              />
            </div>
            */}
            {/*
              <Footer data={appData.main} />
            */}
          </div>
        </Menu>
        <EasterEgg iterations={2} />
        <ToastListener />
        <SubgraphHealthIndicator />
      </ErrorBoundary>
    );
  // }
}

export default App;
