import React, { Component, useState, useEffect } from 'react';
import Header from "components/Header";
import Information from "components/Information";
import Controls from "components/Controls";
import UnityWindow from "components/UnityWindow";
import appData from 'appData.json'



const HomePage: React.FC<> = () => {

  const [gameState, setGameState] = useState(null)
  const [reloadCounter, setReloadCounter] = useState(0)
  const [playerName, setPlayerName] = useState('Player 1')
  const [unloadUnityComponent, setUnloadUnityComponent] = useState(true)

  useEffect(() => {
    const loadData = async () => {
      // Load data from local storage
      let savedGameState = JSON.parse(localStorage.getItem('gameState'));
      if (savedGameState) {
        setGameState(savedGameState);
        setUnloadUnityComponent(false)
      } else {
        // Check for VR headset support
        if (navigator.xr) {
          const supported = await navigator.xr.isSessionSupported('immersive-vr');
          if (supported) {
            setGameState('webXR');
          } else {
            setGameState('default');
          }
        } else {
          setGameState('default');
        }
        setUnloadUnityComponent(false)
      }
    };

    loadData();
  }, []);

  function updateBuild(newState){
    setUnloadUnityComponent(true)
    setGameState(newState)
    setReloadCounter(reloadCounter + 1)
    setUnloadUnityComponent(false)
    // Save data to local storage
    localStorage.setItem('gameState', JSON.stringify(newState));
  }

  return (
    <>
      {appData?//*<Loading isLoading={this.state.isLoading} load={this.load} data={this.state.appData.main}/>*/}
        <>
          <Header data={appData.main} />
          {!unloadUnityComponent &&
            <UnityWindow 
              updateBuild={updateBuild}
              gameState={gameState}
              playerName={playerName}
              setPlayerName={setPlayerName}
            />
          }
          {/*<Controls data={appData.controls} />*/}
          <Information data={appData.information} />
        </>
        :
        null
      }
    </>
  );
};

export default HomePage;