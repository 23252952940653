export default {
  xp: {
    43113: '0xFa647818143Cd033140413951a107b0c091a174c',
    43114: '0x0238F2e8D761FB14181501ab96A123a1488CE2c8'
  },
  proxyXpUnlockWrapper: {
    43113: '0x306e5Ee9b7cA6cF35977F1Cbef8281f821ef0ea1',
    43114: '0xb561388CA4b858c7E40847827506CA81278A3B53'
  },
  proxyNexItems: {
    43113: '0xD1Ffe38BD703AdECCf0924451A8E900449e5dC50',
    43114: '0xa8927E671FB867e8ef3013174C7f097DF6Ac1805'
  },
  proxyGLITCH: {
    43113: '0x388488f170fB9B3B2f681A42D5CDC480740d383c',
    43114: '0x6e33d88871b1664419bdabbc9cce10b37b1cf53d'
  },
  proxyNexWorld: {
    43113: '0xD1Ffe38BD703AdECCf0924451A8E900449e5dC50',
    43114: '0xa8927E671FB867e8ef3013174C7f097DF6Ac1805'
  },
  proxyDataSticks: {
    43113: '0xc20381273458Db8493D87549Bf2C053342370BC3',
    43114: ''
  },
  proxyCoreManager: {
    43113: '0xca00356b14DDa04c96282C127474c56813381315',
    43114: ''
  },
  proxyBossBattles: {
    43113: '0x98d87f3AF9777935bdc5C206BAe126aA7AD786aA',
    43114: ''
  },
  masterChef: { //
    1666600000: '0xd35ffdA78f4376Cf40F195Ef7bc1D6CAd74C1480',
    1666700000: '',
    43113: '0x37aCFD97c3dcEf8b03c9221350494E891e6270Cc',
    43114: '0xC3f6c850985Cc5E569C6331a6b2c38E6CF791730'
  },
  sousChef: { //
    1666600000: '0x27D8C6A506E1d9754Da0e92f4dA2b85E71c66D9D',
    1666700000: '',
    43113: '0x4346dC476AD4a2861C8F6dc8BE87E4ED9Bfe7708',
    43114: '0x4346dC476AD4a2861C8F6dc8BE87E4ED9Bfe7708'
  },
  lotteryV2: {
    1666600000: '0x5aF6D33DE2ccEC94efb1bDF8f92Bd58085432d2c',
    1666700000: '',
    43113: '',
    43114: ''
  },
  multiCall: { //
    1666600000: '0xB38f229099C6E392E614AE3aD217B36fE9cFf08e',
    1666700000: '',
    43113: '0xb813ED4c59b44B5BB59Ede7732130FD7691F7674',
    43114: '0xfB932723115f6C9CB8b0E7676791487dc000f8ee'
  },
  pancakeProfile: { //
    1666600000: '0xbc3ce195895fE249B36fffC780dB68cEff0b875C',
    1666700000: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    43113: '',
    43114: ''
  },
  pancakeRabbits: {
    1666600000: '',
    1666700000: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
    43113: '',
    43114: ''
  },
  bunnyFactory: {
    1666600000: '',
    1666700000: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    43113: '',
    43114: ''
  },
  claimRefund: {
    1666600000: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    1666700000: '',
    43113: '',
    43114: ''
  },
  pointCenterIgo: {
    1666600000: '',
    1666700000: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    43113: '',
    43114: ''
  },
  bunnySpecial: {
    1666600000: '',
    1666700000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43113: '',
    43114: ''
  },
  tradingCompetition: {
    1666600000: '',
    1666700000: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    43113: '',
    43114: ''
  },
  tradingCompetitionV2: {
    1666600000: '',
    1666700000: '',
    43113: '',
    43114: ''
  },
  // easterNft: {
  //   1666600000: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
  //   1666700000: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
  // },
  xpVault: {
    1666600000: '0xa80240Eb5d7E05d3F250cF000eEc0891d00b51CC',
    1666700000: '',
    43113: '',
    43114: ''
  },
  igoPool: {
    1666600000: '0x1B2A2f6ed4A1401E8C73B4c2B6172455ce2f78E8',
    1666700000: '',
    43113: '',
    43114: ''
  },
  predictions: {
    1666600000: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    1666700000: '',
    43113: '',
    43114: ''
  },
  chainlinkOracle: {
    1666600000: '',
    1666700000: '',
    43113: '',
    43114: ''
  },
  bunnySpecialXpVault: {
    1666600000: '',
    1666700000: '',
    43113: '',
    43114: ''
  },
  bunnySpecialPrediction: {
    1666600000: '',
    1666700000: '',
    43113: '',
    43114: ''
  },
  bunnySpecialLottery: {
    1666600000: '',
    1666700000: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    43113: '',
    43114: ''
  },
  farmAuction: {
    1666600000: '',
    1666700000: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    43113: '',
    43114: ''
  },
  AnniversaryAchievement: {
    1666600000: '',
    1666700000: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    43113: '',
    43114: ''
  },
  nftMarket: { //
    1666600000: '0xc93b7e31c45544A28EAE0dB0DF5fD9745173fD67',
    1666700000: '',
    43113: '',
    43114: ''
  },
  nftSale: {
    1666600000: '',
    1666700000: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    43113: '',
    43114: ''
  },
  pancakeSquad: {
    1666600000: '',
    1666700000: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    43113: '',
    43114: ''
  },
}
